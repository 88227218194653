{
  "en": {
    "transfers": {
      "name": "Your fantasy team name",
      "free": "Free transfers",
      "cost": "Transfers penalty (points)",
      "remaining": "Money remaining",
      "positionView": "View by position",
      "teamView": "View by team",
      "sort": "Sort by",
      "week": "Gameweek {number}",
      "selection": "Transfers",
      "makeTransfers": "Make transfers",
      "save": "Save",
      "allTeams": "All Teams",
      "allPlayers": "All players",
      "sortByPoints": "Total points",
      "sortByPrice": "Price",
      "sortByForm": "Form",
      "selectFavouriteTeam": "Select favourite team",
      "favouriteTeam": "Favourite team",
      "unlimited": "Unlimited",
      "playerInTeam": "Player already in the team",
      "maxTeamPlayers": "Your team already contains {number} players from this team",
      "noPositions": "No free place at this position",
      "teamFull": "Team is full",
      "confirmationScreen": "Transfers confirmation",
      "income": "Income players",
      "outcome": "Outcome players",
      "penaltyPoints": "Penalty points for transfers",
      "cancel": "Cancel",
      "approve": "Approve",
      "search": "Search by name",
      "showLineup": "Show lineup",
      "showSeasonPlayers": "Show season players",
      "points": "Points",
      "price": "Price",
      "description": "On this page you can make transfers by removing some players from your team and replacing them with others who will be available in the next game week's squad. The number of free transfers is limited; for additional transfers, your team will lose points from the total amount. The team's budget determines the available players for transfers.",
      "deadlineDescription": "Deadline indicates when the next game week will start.",
      "fromWatchlist": "From watchlist",
      "wildcard": "Wildcard",
      "activating": "Wildcard activating",
      "wildcardDescription": "Wildcard allows you to change all players once per season without any penalties.",
      "wildcardIsActive": "Wildcard is already active.",
      "wildcardIsNotAvailable": "Wildcard is not available.",
      "anotherChip": "Another chips is active.",
      "canBeActivated": "Wildcard can be activated. Once activated you can't cancel it.",
      "activate": "Activate wildcard"
    },
    "week": {
      "previous": "Previous",
      "next": "Next",
      "gameweek": "Gameweek",
      "localTime": "All times are shown in your local time"
    },
    "squad": {
      "title": "Team squad",
      "description": "On this page you can determine the starting lineup of the team for the next game week, assign a captain and priority for substitute players, activate week bonuses.",
      "reservePriority": "Rank players by substitution priority (players on the left to go first), after all games have ended, substitutions will be made automatically.",
      "bonusesHelp": "You can activate one of the game week bonuses. Bonuses are given in limited quantities for the season.",
      "save": "Save team",
      "chips": "Chips",
      "benchBoost": "Bench boost",
      "tripleCaptain": "Triple captain",
      "chipsLimit": "Maximum amount of chips for week",
      "deadline": "Deadline - {value}",
      "lastPoints": "Points",
      "pts": "Total points"
    },
    "squadPoints": {
      "title": "Squad points",
      "totalPoints": "Total points",
      "averagePoints": "Average points",
      "hightestPoints": "Hightest points",
      "week": "Gameweek {number}",
      "description": "This page shows game results and points scored by players during the current active game week.",
      "benchBoostIsActive": "Bench boost is active",
      "tripleCaptainIsActive": "Triple captain boost",
      "noLineup": "You don't have lineup for this week",
      "fieldView": "Field view",
      "listView": "List view"
    },
    "player": {
      "form": "Form",
      "poinstPerGame": "Avg points",
      "lastWeek": "Last Pts",
      "totalPoints": "Total Pts",
      "price": "Price",
      "teamsSelectedBy": "Selection",
      "thisSeason": "This season",
      "previousSeasons": "Previous seasons",
      "noSeasonGames": "There are no games yet",
      "week": "GW",
      "opponent": "Opponent",
      "pts": "Pts",
      "total": "Total",
      "fixtures": "Fixtures",
      "difficulty": "Difficulty",
      "startAt": "Start time",
      "addToWatchlist": "Add to watchlist",
      "removeFromWatchlist": "Remove from watchlist",
      "chance": "% chance of playing",
      "expected": "expected back"
    },
    "transfersStatus": {
      "transfersIn": "Top Transfers in this Gameweek",
      "transfersOut": "Top Transfers out this Gameweek",
      "position": "Position",
      "player": "Player",
      "team": "Team"
    },
    "achievements": {
      "summary": "Summary",
      "points": "Total points"
    },
    "bestPlayers": {
      "week": "Best players of the week",
      "total": "Best players of the season",
      "position": "Position",
      "player": "Player",
      "team": "Team",
      "points": "Points"
    },
    "fantasyLeagueForm": {
      "create": "Create Fantasy League",
      "name": "Name",
      "new": "New Fantasy League",
      "save": "Save Fantasy League"
    },
    "feedbackForm": {
      "create": "Feedback",
      "title": "Title",
      "description": "Description",
      "new": "New feedback",
      "save": "Send feedback",
      "send": "You can directly send your question/feedback/bug report to ",
      "leave": " or just leave here.",
      "telegram": "Telegram"
    },
    "oraculForm": {
      "new": "New oracul",
      "description": "In this form you need to set name of your oracul",
      "name": "Oracul's name",
      "save": "Save oracul"
    },
    "playerActions": {
      "makeCaptain": "Make captain (x2 points)",
      "makeAssistant": "Make captain assistant"
    },
    "fantasyTeamDestroyForm": {
      "button": "Destroy fantasy team",
      "title": "Destroying fantasy team",
      "description": "Are you really would like to destroy your team? This action is irrevertable, fantasy team can't be restored.",
      "destroy": "Destroy"
    },
    "profile": {
      "deadlinesData": "Deadlines data"
    },
    "oraculWeek": {
      "forecast": "Forecast"
    }
  },
  "ru": {
    "transfers": {
      "name": "Название вашей команды",
      "free": "Бесплатные трансферы",
      "cost": "Штраф за трансферы (очков)",
      "remaining": "Остаток бюджета",
      "positionView": "Фильтр по позициям",
      "teamView": "Фильтр по командам",
      "sort": "Сортировка",
      "week": "Игровая неделя {number}",
      "selection": "Трансферы",
      "makeTransfers": "Выполнить трансферы",
      "save": "Сохранить",
      "allTeams": "Все команды",
      "allPlayers": "Все игроки",
      "sortByPoints": "Сумма очков",
      "sortByPrice": "Цена",
      "sortByForm": "Форма",
      "selectFavouriteTeam": "Выберите любимую команду",
      "favouriteTeam": "Любимая команда",
      "unlimited": "Без ограничений",
      "playerInTeam": "Игрок уже в команде",
      "maxTeamPlayers": "Ваш состав уже состоит из {number} игроков этой команды",
      "noPositions": "Нет свободных мест на этой позиции",
      "teamFull": "Команда уже собрана",
      "confirmationScreen": "Подтверждение трансферов",
      "income": "Приходящие игроки",
      "outcome": "Уходящие игроки",
      "penaltyPoints": "Стоимость трансферов",
      "cancel": "Отмена",
      "approve": "Подтвердить",
      "search": "Поиск по имени",
      "showLineup": "Показать состав",
      "showSeasonPlayers": "Показать игроков",
      "points": "Очки",
      "price": "Цена",
      "description": "На этой странице вы можете провести трансферы, удалив из команды некоторых игроков и заменив их на других, которые будут доступны в составе на следующую неделю. Количество бесплатных трансферов ограничено, за дополнительные трансферы ваша команда будет терять очки из общего количества. Бюджет команды определяет доступных игроков для трансферов.",
      "deadlineDescription": "Дедлайн показывает, когда начнётся следующая неделя.",
      "fromWatchlist": "Избранные",
      "wildcard": "Джокер",
      "activating": "Активирование джокера",
      "wildcardDescription": "Джокер позволяет 1 раз за сезон полностью поменять состав команды без штрафов.",
      "wildcardIsActive": "Джокер уже активирован.",
      "wildcardIsNotAvailable": "Джокер не доступен.",
      "anotherChip": "Другой бонус активирован.",
      "canBeActivated": "Джокер может быть активирован. После активации он не может быть отменён.",
      "activate": "Активировать джокер"
    },
    "week": {
      "previous": "Предыдущая",
      "next": "Следующая",
      "gameweek": "Игровая неделя",
      "localTime": "Время указано в вашем часовом поясе"
    },
    "squad": {
      "title": "Состав команды",
      "description": "На этой странице вы можете определить стартовый состав команды на следующую игровую неделю, назначить капитана и приоритет запасных игроков, активировать бонусы недели.",
      "reservePriority": "Расположите игроков по приоритету выхода с замены (игроки слева выходят первыми), после окончания всех игр замены будут выполнены автоматически.",
      "bonusesHelp": "Вы можете активировать один из бонусов игровой недели. Бонусы даются в ограниченном количестве на сезон.",
      "save": "Сохранить изменения",
      "chips": "Бонусы",
      "benchBoost": "Очки запасных",
      "tripleCaptain": "Тройной бонус капитана",
      "chipsLimit": "Достигнут лимит на бонусы",
      "deadline": "Дедлайн - {value}",
      "lastPoints": "Очки",
      "pts": "Сумма очков"
    },
    "squadPoints": {
      "title": "Результаты недели",
      "totalPoints": "Всего очков",
      "averagePoints": "Средний результат",
      "hightestPoints": "Лучший результат",
      "week": "Игровая неделя {number}",
      "description": "На этой странице представлены результаты игр и набранные игроками очки на текущей активной неделе.",
      "benchBoostIsActive": "Бонус за очки запасных",
      "tripleCaptainIsActive": "Тройной бонус капитана",
      "noLineup": "У вас нет активного состава на эту неделю",
      "fieldView": "Расположение",
      "listView": "Список"
    },
    "player": {
      "form": "Форма",
      "poinstPerGame": "Ср очки",
      "lastWeek": "Последняя игра",
      "totalPoints": "Всего очков",
      "price": "Стоимость",
      "teamsSelectedBy": "Выбранность",
      "thisSeason": "Текущий сезон",
      "previousSeasons": "Предыдущие сезоны",
      "noSeasonGames": "Пока нет данных",
      "week": "Неделя",
      "opponent": "Соперник",
      "pts": "Очки",
      "total": "Итого",
      "fixtures": "Расписание",
      "difficulty": "Сложность",
      "startAt": "Начало игры",
      "addToWatchlist": "В избранное",
      "removeFromWatchlist": "Убрать из избранного",
      "chance": "% шанс сыграть",
      "expected": "возвращение"
    },
    "transfersStatus": {
      "transfersIn": "Самые частые приобретения недели",
      "transfersOut": "Самые частые уходы недели",
      "position": "Позиция",
      "player": "Игрок",
      "team": "Команда"
    },
    "achievements": {
      "summary": "Сводка",
      "points": "Очки достижений"
    },
    "bestPlayers": {
      "week": "Лучшие игроки недели",
      "total": "Лучшие игроки сезона",
      "position": "Позиция",
      "player": "Игрок",
      "team": "Команда",
      "points": "Очки"
    },
    "fantasyLeagueForm": {
      "create": "Создать фэнтези лигу",
      "name": "Название",
      "new": "Новая фэнтези лига",
      "save": "Сохранить фэнтези лигу"
    },
    "feedbackForm": {
      "create": "Обратная связь",
      "title": "Заголовок",
      "description": "Текст",
      "new": "Новый отзыв",
      "save": "Отправить",
      "send": "Вы можете напрямую отправить ваш вопрос/отзыв/баг репорт в ",
      "leave": " или оставьте тут.",
      "telegram": "Телеграм"
    },
    "oraculForm": {
      "new": "Новый оракул",
      "description": "В этой форме вам необходимо указать имя вашего оракула",
      "name": "Имя",
      "save": "Сохранить"
    },
    "playerActions": {
      "makeCaptain": "Назначить капитаном (x2 очки)",
      "makeAssistant": "Назначить помощником капитана"
    },
    "fantasyTeamDestroyForm": {
      "button": "Удалить фэнтези команду",
      "title": "Удаление фэнтези команды",
      "description": "Вы точно хотите удалить команду? Это действие нельзя отменить, команду нельзя будет никак восстановить.",
      "destroy": "Удалить"
    },
    "profile": {
      "deadlinesData": "Дедлайны"
    },
    "oraculWeek": {
      "forecast": "Прогноз"
    }
  }
}
